@media (max-width: 1800px) {
  .mynavclass {
    width: 100vw;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 1300px) {
  .mynavclass {
    width: 100vw;
    padding-left: 20px;
    padding-right: 70px;
  }
}

/* lg */
@media (max-width: 1200px) {
  .logo-style {
    width: 20%;
    height: 100%;
  }
  .mynavclass {
    display: none;
  }
}

/* sm */
@media (max-width: 900px) {
  .logo-style {
    width: 30%;
    height: 100%;
  }
}
/* xs */
@media (max-width: 600px) {
  .logo-style {
    width: 50%;
    height: 100%;
    /* display: none; */
  }
}

@media (max-width: 786px) {
  .mynavclass {
    display: none;
  }
}

/* xs */
@media (max-width: 350px) {
  .logo-style {
    width: 70%;
    height: 100%;
    /* display: none; */
  }
}

/* style={{ width: '50%', height: '100%'}} */