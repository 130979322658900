@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Overpass', sans-serif;
}

.hover-card:hover {
    background-color: aqua;
}

.nav-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #08fdd8;
    width: 125px;
    padding: 0.8rem 0rem;
    border-radius: 5px;
    font-size: 0.9rem;
}

button {
    text-decoration: none;
    color: #08fdd8;
    background: none;
    font-size: 1.1rem;
    letter-spacing: 3px;
    font-family: "Open Sans", sans-serif;
    transition: ease-out 0.6s;
    border-radius: 1px;
    animation: fadeInAnimation 1s 1.8s backwards;
}
button:hover {
    box-shadow: inset 260px 0 0 0 #08fdd8;
    cursor: pointer;
    color: black;
}

.contact-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #08fdd8;
    width: 190px;
    padding: 0.8rem 0rem;
    border-radius: 5px;
}

.contact-button a {
    text-decoration: none;
    color: #08fdd8;
    background: none;
    font-size: 1.1rem;
    letter-spacing: 3px;
    font-family: "Open Sans", sans-serif;
    transition: ease-out 0.6s;
    border-radius: 1px;
    animation: fadeInAnimation 1s 1.8s backwards;
}
.contact-button a:hover {
    box-shadow: inset 260px 0 0 0 #08fdd8;
    cursor: pointer;
    color: black;
}

.card-style {
    font-family: "Open Sans", sans-serif;
    transition: ease-out 0.8s;
    background-color: black;
    border-radius: 5px;
    width: 100%;
    color: #08fdd8;
    animation: fadeInAnimation 1s 1.8s backwards;
    border: 1px solid #08fdd8;
    padding: 1rem ;
}

.card-style:hover {
    box-shadow: inset 345px 0 0 0 #08fdd8;
    cursor: default;
    color: black;
}

.card-style:hover Button {
    color: black;
    border: 1px solid black;
}
.card-style:hover h5 {
    color: black;
}